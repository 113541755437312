<template>
  <div class="computations">
    <!-- Price -->
    <div class="computation-card">
      <h1 class="computation-title" v-text="getText('pricing.totalPrice')"></h1>
      <div class="flex-row">
        <p class="computation-description" v-text="getText('pricing.totalPrice.info')"></p>
        <i class="fa fa-caret-down"></i>
      </div>
      <div class="price-col">
        <SexyPrice :price="totalPrice" tag="h1"/>
      </div>
      <div class="computation-description" v-html="getTextParagraphs('price.info')"></div>
    </div>

    <!-- Energetic Complexity -->
    <div class="computation-card">
      <h1 class="computation-title" v-text="getText('energetics.totalEnergyTaken')"></h1>
      <div class="flex-row">
        <p class="computation-description" v-text="getText('energetics.totalEnergyTaken.info')"></p>
        <i class="fa fa-caret-down"></i>
      </div>
      <div class="price-col energy" v-if="hasEnergetics">

        <SexyEnergyConsumption :consumption="energeticsOutputs.totalEnergyTaken" tag="h1" />
        <PieDiagram :chartData="translateDiagram(energeticsOutputs.distributionOfConsumptionDiagramData)" title="" />
        
        <div v-show="energeticsWarnings && energeticsWarnings.length" class="relative">
          <i 
            @click="showWarnings = true" 
            class="warning-button fa fa-exclamation-triangle">
          </i>   
        </div>

        <MyDialog 
          heading="Nalezené problémy" 
          :show="showWarnings" 
          @input="showWarnings = false" >
          <p class="error" 
            v-for="(warning, idx) in energeticsWarnings" 
            :key="idx">
            <i class="fa fa-exclamation-triangle" style="padding-right: 10px"></i>
            <span v-text="getText(warning.textId, warning.params)"></span>
          </p>
          <button @click="showWarnings = false" class="primary">OK</button>
        </MyDialog>

        <JsonDialog v-if="hasEnergetics" :data="energetics" :show="showEnergeticsDetails" @input="showEnergeticsDetails = $event" />
        
      </div>
    </div>
    
    <!-- Ecological Comlexity -->
    <div class="computation-card">
      <h1 class="computation-title" v-text="getText('energetics.totalPrimaryNonRenewableEnergy')"></h1>
      <div class="flex-row">
        <p class="computation-description" v-text="getText('energetics.totalPrimaryNonRenewableEnergy.info')"></p>
        <i class="fa fa-caret-down"></i>
      </div>
      <div class="price-col" v-if="hasEnergetics">
        <SexyEnergyConsumption :consumption="energeticsOutputs.totalPrimaryNonRenewableEnergy" tag="h1" />
        <PieDiagram :chartData="translateDiagram(energeticsOutputs.primaryNonRenewableEnergyDiagramData)" title="" />
      </div>
    </div>

    <!-- Price for Energy -->
    <div class="computation-card">
      <h1 class="computation-title" v-text="getText('energetics.totalPrice')"></h1>
      <div class="flex-row">
        <p class="computation-description" v-text="getText('energetics.totalPrice.info')"></p>
        <i class="fa fa-caret-down"></i>
      </div>
      <div class="price-col" v-if="hasEnergetics">
        <SexyYearlyCosts :value="energeticsOutputs.totalPrice" tag="h1" />
        <PieDiagram :chartData="translateDiagram(energeticsOutputs.priceOfEnergyDiagramData)" title="" />
      </div>
    </div>

    <!-- Legislative Requirements - Traffic Light -->
    <div class="computation-card">
      <h1 class="computation-title" v-text="getText('energetics.legalCertification')"></h1>
      <div class="flex-row">
        <p class="computation-description" v-text="getText('energetics.legalCertification.info')"></p>
        <i class="fa fa-caret-down"></i>
      </div>
      <div class="computation-content" v-if="hasEnergetics">
        <EnergeticsLights :trafficLights="energeticsOutputs.energeticsTrafficLights" />
      </div>
    </div>

    <EnergeticTest v-show="false" />
    <!-- Donation - Grant -->
    <div class="computation-card" v-if="hasEnergetics && getFeatureFlag('hasGrantNZU')">
      <h1 class="computation-title" v-text="getText('energetics.grantNZU')"></h1>
      <div class="flex-row">
        <p class="computation-description" v-text="getText('energetics.grantNZUTotal')"></p>
        <i class="fa fa-caret-down"></i>
      </div>
      <div class="price-col">
        <SexyPrice :price="grantNZUSummary.grantTotal" tag="h2"/>
      </div>
    </div>

    <!-- Grants by Sections -->
    <div class="computation-card" v-if="hasEnergetics && getFeatureFlag('hasGrantNZU')">
      <h1 class="computation-title" v-text="getText('energetics.grantNZUByChaptersTitle')"></h1>
      
      <!-- <json-dialog :data="grantNZUPartials" :show="true" /> -->
      
      <!-- energetics.grantNZUChapterB    -->
      <div class="sub-grant">
        <strong v-text="getText('energetics.grantNZUChapterB')"></strong>
      </div>
      <div class="price" >
        <SexyPrice :price="grantNZUPartials.grantSectionB_NewBulding.grant" tag="label"/>
      </div>
      
      <!-- energetics.grantNZUChapterC5    -->
      

      <!-- energetics.grantNZUChapterD1    -->
      <div class="sub-grant">
        <strong v-text="getText('energetics.grantNZUChapterD1')"></strong>
      </div>
      <div class="price" >
        <SexyPrice :price="grantNZUPartials.grantSectionD1_GreenRoof.grant" tag="label"/>
      </div>
      
      <!-- energetics.grantNZUChapterD2    -->
      <div class="sub-grant">
        <strong v-text="getText('energetics.grantNZUChapterD2')"></strong>
      </div>
      <div class="price" >
        <SexyPrice :price="grantNZUPartials.grantSectionD2_RainwaterUse.grant" tag="label"/>
      </div>
      
      <!-- energetics.grantNZUChapterD3    -->
      
      <!-- energetics.grantNZUBonusTotal    
      <div class="sub-grant">
        <strong v-text="getText('energetics.grantNZUBonusTotal')"></strong>
      </div>
      <div class="price" >
        <SexyPrice :price="grantNZUSummary.bonusTotal" tag="label"/>
      </div>
      -->
      <!-- energetics.grantNZUEnvironmentallyFriendlySolution    -->
      <div class="sub-grant">
        <strong v-text="getText('energetics.grantNZUEnvironmentallyFriendlySolution')"></strong>
      </div>
      <div class="price" >
        <SexyPrice :price="grantNZUPartials.grantEnvironmentallyFriendlySolution.grant" tag="label"/>
      </div>
     
    </div>
    <!-- Grant Standings in Section B -->
    <div class="computation-card" v-if="hasEnergetics && getFeatureFlag('hasGrantNZU')">
      <h1 class="computation-title" v-text="getText('energetics.grantNZUChapterBDetailsTitle')"></h1>
      <NZUChapterBDetail 
        :text="getText('energetics.grantNZUChapterBNormalisedHeatingLoad')" 
        parameter="normalisedHeatingLoad"
      />

      <NZUChapterBDetail 
        :text="getText('energetics.grantNZUChapterBPrimaryNonRenewableEnergyRatio')" 
        parameter="normalizedPrimaryNonRenewableEnergy"
      />

      <NZUChapterBDetail 
        :text="getText('energetics.grantNZUChapterBUValueRatio')" 
        parameter="UValueRatio"
        simplified
      />

      <NZUChapterBDetail 
        :text="getText('energetics.grantNZUChapterBUemHeatCoefRatio')" 
        parameter="uemHeatCoefRatio"
      />

      <NZUChapterBDetail 
        :text="getText('energetics.grantNZUChapterBMaxSummerDailyTemp')" 
        parameter="maxSummerDailyTemp"
        simplified
      />

      <NZUChapterBDetail 
        :text="getText('energetics.grantNZUChapterBHeatRecovery')" 
        parameter="heatRecovery"
        simplified
      />

      <NZUChapterBDetail 
        :text="getText('energetics.grantNZUChapterBAllowedEnergyCarriers')" 
        parameter="energyCarrierGrantCondition"
        simplified
      />
    </div>

  </div>
</template>

<script>
import EnergeticTest from "./../tests/EnergeticTest"
import EnergeticsLights from "./EnergeticsLights.vue"
import { mapGetters } from "vuex";
import MyDialog from '../../components/MyDialog.vue';
import JsonDialog from './../tests/JsonDialog.vue';
import PieDiagram from './PieDiagram.vue';
import SexyPrice from './SexyPrice.vue';
import SexyEnergyConsumption from './SexyEnergyConsumption.vue';
import SexyYearlyCosts from './SexyYearlyCosts.vue';
import NZUChapterBDetail from './NZUChapterBDetail.vue';


export default {
  components: {
    EnergeticsLights,
    EnergeticTest,
    MyDialog,
    JsonDialog,
    PieDiagram,
    SexyPrice,
    SexyEnergyConsumption,
    SexyYearlyCosts,
    NZUChapterBDetail,
  },
  computed: {
    ...mapGetters(['getTextParagraphs']),
    ...mapGetters("result", [
      "totalPrice",
      "energetics",
      "hasEnergetics", 
      "energeticsOutputs",
      "energeticsWarnings",
      "grantNZUSummary",
      "grantNZUPartials",
    ]),
    ...mapGetters("configuration/featureFlags", ["getFeatureFlag"]),
    ...mapGetters(["refreshToken", "getText"]),
  },
  data() {
    return {
      showWarnings: false,
      showEnergeticsDetails: false,
    }
  },
  methods: {
    translateDiagram(data) {
      const newData = []
      if (data.length < 1) return newData;
      const heading = data[0];
      newData.push([ this.getText(heading[0]), this.getText(heading[1]) ]);
      for (let i = 1; i < data.length; ++ i) {
        const row = data[i];
        newData.push([ this.getText(row[0]), row[1]]);
      }
      return newData;
    }
  },
};
</script>

<style lang="scss">
.computations {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  .computation-card {
    padding: 13px;
    border: 1px solid #0000001f;
    border-radius: 4px;

    h1.computation-title {
      font: 500 20px/24px Roboto;
      letter-spacing: 0.15px;
    }

    .computation-description {
      font: 300 14px Roboto;
      letter-spacing: 0.13px;
      color: #000000de;
    }

    h1.computation-title + .flex-row {
      border-bottom: solid 1px #0000001f;
    }

    .computation-content {
      width: 100%;
      height: calc(100% - 95px);
      min-height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button.secondary {
        font-weight: 600;
        letter-spacing: 1.43px;
      }
    }

    i {
      color: $secondary-color;
      font-size: 20px;
      margin: auto 16px auto auto;
    }

    i.warning-button {
      font-size: 3em;
      color: $primary-color;
      cursor: pointer;
      &:hover {
        color: lighten($primary-color, 20%);
      }
    }
    .price {
      text-align: center;
    }
    .price-col {
      h1 {
        font-weight: 300;
      }
      h2 {
        font-weight: 400;
      }
    }
    
  }
}
</style>